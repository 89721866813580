.row {
  $spacing: 4px;
  margin-right: -$spacing;
  margin-left: -$spacing;

  & > [class^="col-"],
  & > [class*=" col-"] {
    padding-right: $spacing;
    padding-left: $spacing;
  }

  &.full-gutters {
    & > [class^="col-"],
    & > [class*=" col-"] {
      padding-top: $spacing;
      padding-bottom: $spacing;
    }
  }

  &.no-gutters {
    & > [class^="col-"],
    & > [class*=" col-"] {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@for $i from 1 through 100 {
  .margin-bottom-#{$i} { margin-bottom:$i + 0px; }
  .margin-top-#{$i} { margin-top:$i + 0px; }
  .margin-left-#{$i} { margin-left:$i + 0px; }
  .margin-right-#{$i} { margin-right:$i + 0px; }

  .padding-bottom-#{$i} { padding-bottom:$i + 0px; }
  .padding-top-#{$i} { padding-top:$i + 0px; }
  .padding-left-#{$i} { padding-left:$i + 0px; }
  .padding-right-#{$i} { padding-right:$i + 0px; }

  .padding-left-#{$i}-i { padding-left:$i + 0px !important;; }
  .padding-right-#{$i}-i { padding-right:$i + 0px !important; }

  @include media-breakpoint-down(xs) {
    .margin-xs-bottom-#{$i} { margin-bottom:$i + 0px; }
    .margin-xs-top-#{$i} { margin-top:$i + 0px; }
    .margin-xs-left-#{$i} { margin-left:$i + 0px; }
    .margin-xs-right-#{$i} { margin-right:$i + 0px; }

    .padding-xs-bottom-#{$i} { padding-bottom:$i + 0px; }
    .padding-xs-top-#{$i} { padding-top:$i + 0px; }
    .padding-xs-left-#{$i} { padding-left:$i + 0px; }
    .padding-xs-right-#{$i} { padding-right:$i + 0px; }
  }

  @include media-breakpoint-only(sm) {
    .margin-sm-bottom-#{$i} { margin-bottom:$i + 0px; }
    .margin-sm-top-#{$i} { margin-top:$i + 0px; }
    .margin-sm-left-#{$i} { margin-left:$i + 0px; }
    .margin-sm-right-#{$i} { margin-right:$i + 0px; }

    .padding-sm-bottom-#{$i} { padding-bottom:$i + 0px; }
    .padding-sm-top-#{$i} { padding-top:$i + 0px; }
    .padding-sm-left-#{$i} { padding-left:$i + 0px; }
    .padding-sm-right-#{$i} { padding-right:$i + 0px; }
  }

  @include media-breakpoint-up(md) {
    .margin-md-bottom-#{$i} { margin-bottom:$i + 0px; }
    .margin-md-top-#{$i} { margin-top:$i + 0px; }
    .margin-md-left-#{$i} { margin-left:$i + 0px; }
    .margin-md-right-#{$i} { margin-right:$i + 0px; }

    .padding-md-bottom-#{$i} { padding-bottom:$i + 0px; }
    .padding-md-top-#{$i} { padding-top:$i + 0px; }
    .padding-md-left-#{$i} { padding-left:$i + 0px; }
    .padding-md-right-#{$i} { padding-right:$i + 0px; }
  }
}