header {
  background: $header-background;
  box-shadow: 0 3px 26px 0 rgba(0, 0, 0, 0.66);
  position: relative;

  nav {
    a {
      display: inline-block;
      padding: 6px 25px;
      vertical-align: middle;
      color: white;
      font-size: 14px;

      &:hover, &.active {
        color: $highlight;
      }

      &.logo {
        padding: 6px 0;
        margin-right: 25px;

        img {
          &.logo-desktop {
            height: 38px;
            display: none;

            @include media-breakpoint-up(md) {
              display: block;
            }
          }

          &.logo-mobile {
            width: auto;
            display: none;
            @include media-breakpoint-down(sm) {
              display: block;
            }
          }
        }
      }

      @include media-breakpoint-down(sm) {
        padding: 6px 10px;

        &.logo {
          margin-right: 10px;
        }
      }
    }

    .toggle-button {
      text-align: right;
      display: inline-block;

      .btn.btn-default {
        padding-left: 10px;
        padding-right: 10px;

        span {
          vertical-align: middle;
        }

        svg {
          margin-right: 10px;
          vertical-align: middle;
          font-size: 16px;

          &.fa-times {
            color: $dark-red;
          }

          &.fa-check {
            color: $true-green;
          }
        }
      }
    }
  }
}