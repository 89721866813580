.nsfw-filter {
  .btn.btn-default {
    svg {
      &.fa-times {
        color: $dark-red;
      }

      &.fa-check {
        color: $true-green;
      }
    }
  }
}