.widget {
  @include border-radius(6px);
  // border:1px solid #333;
  margin-top: 8px;
  background: $bg-black-widget;

  &:first-of-type {
    margin-top: 0;
  }

  .widget-footer, .widget-header {
    padding: 8px 15px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    color: $text-color;
    display:block;

    &.no-border {
      border-bottom:none;
    }

    span {
      text-transform: none;
      font-size: 11px;
      font-weight: 400;
      letter-spacing: 0;
      color: #888;
    }

    h1,h2 { display:inline-block; }
    h1 { font-size:13px; }
  }

  .widget-header {
    @include border-radius(6px 6px 0 0);
    border-bottom: 1px dashed #444;
    background: $light-background;

    h1,h2,h3,h4,h5 {
      margin: 0;
      padding: 0;
    }
  }

  .widget-footer {
    @include border-radius(0 0 6px 6px);
    border-top: 2px dashed #444;
  }

  .widget-body {
    padding:10px 0;

    .section-headline {
      background: $dark-background;
    }

    .feature {
      padding: 10px 15px;
    }

    .table-row {
      border-bottom:1px dashed #444;
      color: #aaa;
      padding: 0 15px;

      &.headline-row {
        font-weight: bold;
        color: white;
      }

      &:hover {
        background:$bg-grey-transparent;
        transition:background 0.25s ease-in-out;
      }

      &:first-child {
        margin-top:0;
      }

      &:last-child {
        margin-bottom:0;
        border-bottom:none;
        @include border-radius(0 0 6px 6px);
      }

      [class*="col-"] {
        padding: 8px 0;
      }
    }

    &.larger-text {
      line-height: 125%;
    }

    .section-headline {
      border-radius: 0;
      margin: 0;
    }
  }

  .widget-body, .widget-footer {
    &.full-padding, .full-padding {
      padding:10px 15px;
    }

    .padding-sides {
      padding:0 15px;
    }

    &.with-list {
      ul {
        list-style-type:disc;
        margin:10px 20px;

        li {
          margin:5px 0;
        }
      }
    }

    .object-wrapper {
      border-bottom: 1px solid $border-color;
      padding-bottom: 4px;
      margin-bottom: 4px;

      &:last-of-type {
        border: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    &.no-padding {
      padding:0;
    }

    .pagination {
      margin:0;

      li {
        a {
          background:transparent;
          border-color:#444;
          color:#bbb;

          &:hover {
            color:#fff;
          }
        }

        &.active a { color:white; }
        &.disabled { display:none; }

        @media only screen and (max-width: 1024px) {
          &.page { display:none; }
        }
      }
    }
  }

  .widget-footer .table-row {
    padding:0 !important;

    .table-col {
      text-align:left !important;
      padding:0 !important;

      strong {
        font-size:16px;
      }
    }
  }
}