.stream-toggle, .settings-toggle {
  padding: 6px 0;
  margin-left: 15px;
  font-size: 16px;
}

.stream-toggle {
  display: none;
}

.overlay-background {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  background: rgba(0,0,0,0.75);
}

.overlay-content {
  position: absolute;
  width: 50%;
  height: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 110;
  margin: auto;
  background: black;
  @include border-radius();
  border:1px solid $border-color;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  &.small {
    width: 35%;
    height: 20%;

    @include media-breakpoint-down(lg) {
      width: 50%;
    }

    @include media-breakpoint-down(sm) {
      width: 75%;
    }
  }

  .header {
    padding: 5px;
    text-align: right;

    button {
      font-size: 20px;
    }
  }

  .inner-content {
    padding: 0 15px;

    &.full-padding {
      padding: 15px;
    }
    
    &.centered {
      text-align: center;
    }

    h2 {
      margin-bottom: 15px;
    }

    button {
      display: block;
      width: 100%;
      font-size: 16px;
    }

    .stream-50, .stream-100 {
      display: inline-block;
      text-align: center;
      padding: 25px 0;
      border: 1px solid $border-color;
    }

    .stream-50 {
      width: 50%;
    }

    .stream-100 {
      width: 100%;
    }
  }
}