#watchlist {
  position: fixed;
  right: 15px;
  top: 0;
  background: $dark-grey-blue;
  padding-bottom: 10px;
  @include border-radius-bottom();
  z-index: 1000;
  box-shadow: 0 3px 26px 0 rgba(0, 0, 0, 0.66);
  min-width: 165px;

  .watchlist-header {
    padding: 16px 10px;
    color:white;
    font-size: 18px;
    background: $header-background;
    margin-bottom: 10px;
    text-align: center;
  }

  .stream-list {
    text-align: center;
    padding: 0 10px;

    button {
      padding: 3px 0;
      display: block;
      font-size: 13px;
      margin-bottom: 10px;
    }
  }

  .view-modes {
    @include media-breakpoint-down(sm) {
      display: none;
    }

    button {
      background-color: $slate;
      color: #707070;
      width: 50%;
      display: inline-block;
      padding: 5px 0;
      
      .image {
        display: block;
        height: 17px;
      }

      &.horizontal .image {
        background: url('./horizontalmode_disabled.png') center center no-repeat;
      }

      &.vertical .image {
        background: url('./verticalmode_disabled.png') center center no-repeat;
      }

      &.active, &:hover {
        background-color: $grey-blue;
        color:white;

        &.horizontal .image {
          background: url('./horizontalmode.png') center center no-repeat;
        }

        &.vertical .image {
          background: url('./verticalmode.png') center center no-repeat;
        }
      }
    }
  }

  .start-container {
    margin-top: 10px;
    padding: 0 10px;
  }
}

.add-button {
  padding: 0 4px;
}