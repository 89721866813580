.tag-line {
  margin-bottom: 12px;

  a {
    padding: 8px;
    border:1px solid $border-color;
    @include border-radius;
    margin-right: 8px;
    font-weight: bold;

    &:first-child {
      margin-left: 8px;
    }
  }
}