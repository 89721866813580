.stream-teaser, .game-teaser {
  display: block;
  border: 1px solid $border-color;
  @include border-radius();
  background: $dark-background;

  img {
    @include border-radius-top();
  }

  h4 {
    padding: 6px;
    white-space: nowrap;
    overflow: hidden;
  }
}

.game-teaser {
  position: relative;

  h4 {
    width: 100%;
    position: absolute;
    bottom: 0;
    background: rgba(19,24,34, 0.9);
    @include border-radius-bottom();
  }
}

.stream-teaser {
  .image-block {
    position: relative;

    h4 {
      position: absolute;
      top: 0;
      background: rgba(19,24,34, 0.75);
      max-width: 100%;
      min-width: 100%;

      .add-button {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 5;
        background: $highlight;
        color: $dark;
        border-top-right-radius: 6px;
        font-size: 19px;
        padding: 3px 8px;
      }
    }

    .viewer-count {
      position: absolute;
      bottom: 5px;
      right: 5px;
      padding: 3px;
      background: rgba(19,24,34, 0.75);
      @include border-radius();
    }
  }
}

.game-section.overview {
  @include media-breakpoint-down(sm) {
    .col-6:last-of-type {
      display: none;
    }
  }
}