@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700');

@import "../assets/css/reset";

@import "../../node_modules/bootstrap-4-grid/scss/grid";

@import "../assets/css/variables";

@import "../assets/css/mixins/mixins";

@import "../assets/css/buttons";
@import "../assets/css/forms";
@import "../assets/css/pagination";
@import "../assets/css/spacings";
@import "../assets/css/grid";
@import "../assets/css/loader";

@import "../components/Footer/Footer";
@import "../components/Header/Header";

@import "../components/Form/StreamForm";
@import "../components/Stream/Stream";
@import "../components/Twitch/Teaser";
@import "../components/Widget/Widget";
@import "../components/NsfwSwitch/NsfwSwitch";
@import "../components/Watchlist/Watchlist";
@import "../components/Overlay/Overlay";
@import "../components/Shell/Shell";
@import "../components/LandingPage/LandingPage";
@import "../components/Tags/TagLine";

html, body {
  height:100%;
  position: relative;
}

body {
  font-size: 14px;
  color: $text-color;
  font-family: 'Open Sans', sans-serif;
  background: $dark-background;
  // background: $dark-background;
}

strong, b {
  font-weight: bold;
}

#root, .wrapper {
  min-height: 100%;
}

.wrapper {
  margin-bottom: 35px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.hidden {
  display: none !important;
}

.visible {
  display: inline-block !important;
}

button, a, a:hover, a:active, a:visited, a:focus {
  text-decoration:none;
  outline:none;
}

button {
  border: none;
  background: none;
  cursor: pointer;
}

button, a {
  color: $link;
  @include transition;

  &:hover {
    color: $link-active;
  }
}

.btn {
  text-transform:uppercase;
  font-size:12px;

  &.btn-danger {
    background: #d9534f;
    color: white;
    border: 0;
    font-weight: 600;
    text-transform: uppercase;
    transition: all 0.15s ease-in-out;

    &:hover {
      background: #c9302c;
    }
  }

  &.btn-primary {
    background: $highlight;
    color: #511616;
    border: 0;
    font-weight: 600;
    text-transform: uppercase;
    transition: all 0.15s ease-in-out;
    border: none;
    box-shadow: 4px 4px 5px 3px rgba(0,0,0,0.5);

    &:hover {
      background: $highlight-light;
      color: #792121;
    }

    &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active {
      background: $highlight-active;
      color: #792121;
      border: none;
      box-shadow: none;
    }
  }

  &.btn-default {
    border-radius: 5px;
    border: solid 1px white;
    padding: 7px 23px;
  }

  &.btn-lg {
    font-size: 16px;
  }

  &.is-disabled, &.btn-inactive {
    cursor:default;
  }

  &:focus {
    box-shadow: none;
  }
}

form {
  input.form-control {
    border: 1px solid $dark;
  }
}

.fz-1 { font-size: 14px; }
.fz-2 { font-size: 15px; }
.fz-3 { font-size: 16px; }
.fz-4 { font-size: 17px; }
.fz-5 { font-size: 18px; }

.fz-33 { font-size: 33px;}

.upcase { text-transform: uppercase;}

.white { color: white; }
.dark { color: $text-dark; }
.yellow { color: $macaroni-and-cheese; }

h1,h2,h3,h4,h5 {
  color: white;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

.section-headline {
  text-transform: uppercase;
  text-align: center;
  padding: 8px 0;
  margin-bottom: 8px;
  background: $light-background;
  @include border-radius;
  font-weight: bold;
}

.img-fluid {
  max-width: 100%;

  &.full {
    width: 100%;
  }
}

.container {
  &.default-padding {
    padding-top: 30px;
    padding-bottom: 22px;
  }
}

.white-container {
  background: white;
}

.blue-container {
  background: $blue-background;
}

.black-container {
  background: $dark-background;
}

.cover-container {
  background-image: url('../assets/images/background.jpg');
  background-position: center center;
  background-size: cover;

  &.nsfw {
    background-image: url('../assets/images/background-nsfw.jpg');
  }
}

.logo-area {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}