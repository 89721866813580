footer.page-footer {
  background: $almost-black;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px 0;
  color: white;
  z-index: 1000;

  a {
    display: inline-block;
    padding: 0 6px;
    text-transform: uppercase;
  }
}