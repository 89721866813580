.landingpage {
  .header-overwrite {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 10;
    height: 250px;
    background-image: linear-gradient(to bottom, rgb(19,24,34), rgb(19,24,34) 50%, rgba(19, 24, 34, 0));
    text-align: center;
    padding: 40px;

    img {
      height: 45px;
    }
  }

  .landing-content {
    position: relative;

    section .container {
      position: relative;
    }

    .gaming {
      .cover-block {
        text-align: center;
        background: url("./images/game-cover.jpg") no-repeat center center;
        background-size: cover;
        height: 632px;;
      }

      .cover-content {
        position: absolute;
        top: 110px;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 11;

       form {
        margin-top: 60px;
        width: 50%;

        @include media-breakpoint-down(sm) {
          width: 100%;
        }

          button {
            box-shadow: none;
          }
        }
      
        h1 {
          text-shadow: 0 3px 6px #000000;
          font-size: 30px;
          font-weight: bold;
          line-height: 1.37;
          color: $macaroni-and-cheese;
        }

        @include media-breakpoint-down(sm) {
          top: 50px;

          h1 {
            font-size: 22px;
          }

        }
      }

      .background-container {
        background: url("./images/game-background.jpg") no-repeat center center;
        background-size: cover;
      }
    }

    .chaturbate {
      .cover-block {
        text-align: center;

        .cover-content {
          position: absolute;
          top: 110px;
          left: 0;
          right: 0;
          width: 100%;
          z-index: 11;

          h1 {
            text-shadow: 0 3px 6px #000000;
            font-size: 30px;
            font-weight: bold;
            line-height: 1.37;
            color: $macaroni-and-cheese;
          }

          .btn {
            margin-top: 140px;
          }

          @include media-breakpoint-down(sm) {
            top: 50px;

            h1 {
              font-size: 22px;
            }

            .btn {
              margin-top: 40px;
            }
          }
        }
      }

      .background-container {
        background: url("./images/cb-background.jpg") no-repeat center center;
        background-size: cover;
      }
    }

    .game-landingpage {
      .img-fluid {
        @include media-breakpoint-down(sm) {
          margin-top: 50px;
        }
      }
      
      .cover-block {
        text-align: center;

        .cover-content {
          position: absolute;
          top: 110px;
          left: 0;
          right: 0;
          width: 100%;
          z-index: 11;

          form {
            margin-top: 60px;
            width: 50%;
    
            @include media-breakpoint-down(sm) {
              display: none;
            }
    
              button {
                box-shadow: none;
              }
            }

          h1 {
            text-shadow: 0 3px 6px #000000;
            font-size: 30px;
            font-weight: bold;
            line-height: 1.37;
            color: $macaroni-and-cheese;
          }

          .btn {
            margin-top: 140px;
          }

          @include media-breakpoint-down(sm) {
            top: 50px;

            h1 {
              font-size: 22px;
            }

            .btn {
              margin-top: 40px;
            }
          }
        }
      }

      .background-container {
        background: url("./images/fortnite-background.jpg") no-repeat center center;
        background-size: cover;
      }
    }
  }
}