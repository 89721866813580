$text-color:          #999;
$text-dark:           #797979;
$bg-black:            rgb(34,34,34);
$bg-black-widget:     #333;

$highlight:           #f9be37;
$highlight-light:     #ffd341;
$highlight-active:    rgba(249,190,55, 0.9);
$highlight-active-light:    rgba(249,190,55, 0.5);

$light-background:    rgb(51,51,51);
$light-hover:         rgba(51,51,51, 0.5);

// $dark-background:     rgb(20,20,20);
$dark-hover:          rgba(20,20,20, 0.75);

$bg-grey-transparent: rgba(68,68,68, 0.5);
$bg-grey:             rgba(68,68,68, 1.0);
$border-color:        $bg-grey-transparent;

$link:                      #ccc;
$link-hover:                white;
$link-active:               $link-hover;

// redesign 1.0 - styles
// Zeplin
$dark: #0d121c;
$dark-grey-blue: #333d52;
$grey-blue: #616c83;
$slate: #48536a;
$white: #ffffff;
$almost-black: #0a0d14;
$almost-black-two: #06090f;
$dark-two: #20293a;
$true-green: #008e05;
$dark-red: #8e0000;
$macaroni-and-cheese: #f9be37;

// Custom
$blue-background:       #20293a;
$dark-background:       #131822;

$header-background:     #0d121c;

$text-grey:             #797979;