@import "_borders";
@import "_buttons";
@import "_forms";
@import "_gradients";
@import "_hover";
@import "_pagination";

@mixin stream-container($width, $height) {
  display:inline-block;
  width: $width;
  height: $height;

  iframe {
    width: 100%;
    height: 100%;
  }
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin border-radius-left($radius: 6px) {
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
  -moz-border-top-left-radius: $radius;
  -moz-border-bottom-left-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  -webkit-border-bottom-left-radius: $radius;
}

@mixin border-radius-right($radius: 6px) {
  border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
  -moz-border-top-right-radius: $radius;
  -moz-border-bottom-right-radius: $radius;
  -webkit-border-top-right-radius: $radius;
  -webkit-border-bottom-right-radius: $radius;
}

@mixin border-radius-top($radius: 6px) {
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
  -moz-border-top-left-radius: $radius;
  -moz-border-top-right-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  -webkit-border-top-right-radius: $radius;
}

@mixin border-radius($radius: 6px) {
  border-radius: $radius;
}

@mixin border-radius-bottom($radius: 6px) {
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
  -moz-border-bottom-left-radius: $radius;
  -moz-border-bottom-right-radius: $radius;
  -webkit-border-bottom-left-radius: $radius;
  -webkit-border-bottom-right-radius: $radius;
}

@mixin opacity($percent) {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$percent})";
  filter: alpha(opacity=#{$percent});
  -moz-opacity: $percent / 100;
  -khtml-opacity: $percent / 100;
  opacity: $percent / 100;
}

@mixin link($color, $hoverColor, $hoverTextDecoration: none) {
  color: $color;
  text-decoration: none;
  cursor: pointer;

  &:link, &:visited, &:link, &:active {
    color: $color;
  }

  @include media-breakpoint-up(md) {
    &:hover {
      color: $hoverColor;
      text-decoration: $hoverTextDecoration;
    }
  }
}

@mixin transition($time: 0.3s) {
  -webkit-transition: all $time;
  -moz-transition: all $time;
  transition: all $time;
}

@mixin box-shadow($top, $left, $blur, $size, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $size $color;
    -moz-box-shadow:inset $top $left $blur $size $color;
    box-shadow:inset $top $left $blur $size $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $size $color;
    -moz-box-shadow: $top $left $blur $size $color;
    box-shadow: $top $left $blur $size $color;
  }
}

@mixin ellipsis() {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin background {
  background:white;
  cursor:pointer;
  opacity:0.85;
  width:100%;
  height:100%;
  position:fixed;
}