.stream-page {
  line-height: 0;

  .stream-container {
    text-align: center;
    position: relative;
    vertical-align: middle;

    .stream-embed {
      position: relative;
    }
  }

  &.streams-1 .stream-container {
    @include stream-container(100%, 100%);
  }

  &.streams-2 .stream-container {
    @include stream-container(100%, 50%);

    .chaturbate, .livejasmin {
      width: 50%;
    }
  }

  &.streams-3 .stream-container, &.streams-4 .stream-container {
    @include stream-container(50%, 50%);

    .chaturbate {
      width: 65%;
    }
  }

  &.streams-5 .stream-container, &.streams-6 .stream-container {
    @include stream-container(50%, 33%);

    .chaturbate, .livejasmin {
      width: 72%;
    }
  }

  &.streams-3 .stream-container:last-of-type {
    @include stream-container(100%, 50%);

    .chaturbate {
      width: 50%;
    }
  }

  &.streams-5 .stream-container:last-of-type {
    @include stream-container(100%, 33%);

    .chaturbate {
      width: 50%;
    }
  }

  .stream-container {
    .chat-btn, .link-btn {
      display: none;
    }

    .button-wrapper {
      position: absolute;
      left: 10px;
      bottom: 65px;
      @include clearfix;
      z-index: 10000;

      .btn {
        margin-right: 5px;
        float: left;
      }
    }

    .remove-btn {
      opacity: 0;
      @include transition();
    }

    &:hover .remove-btn {
      opacity: 1;
    }

    &.chaturbate {
      .link-btn {
        display: block;
        opacity: 0;
        @include transition();
      }

      &:hover .link-btn {
        opacity: 1;
      }
    }

    &.twitch, &.mixer {
      .chat-btn {
        display: block;
        opacity: 0;
        @include transition();
      }

      &:hover .chat-btn {
        opacity: 1;
      }

      &.display-chat {
        iframe:first-of-type {
          width: 75%;
        }

        iframe:last-of-type {
          width: 25%;
        }
      }
    }
  }

  &.horizontal {
    &.streams-2 .stream-container {
      @include stream-container(50%, 100%);

      .livejasmin {
        height: 50%;
        margin-top: 25%;
      }

      .chaturbate {

      }
    }
  }

  @include media-breakpoint-down(md) {
    .stream-container, .stream-container iframe {
      width: 100% !important;
    }

    &.streams-1 .stream-container {
      height: 50%;

      &.chaturbate iframe {
        height: 58%;
      }
    }

    &.streams-2 .stream-container {
      height: 50% !important;

      &.chaturbate iframe {
        height: 58%;
      }
    }

    &.streams-3 .stream-container {
      height: 33% !important;
    }
  }
}